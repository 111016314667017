import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { ItemDetailComponent } from './pages/itemDetail/itemDetail.component';
import { AuthGuardService as AuthGuard } from './pages/auth/helpers/auth.guard';
import { RoleGuardService as RoleGuard } from './pages/auth/helpers/role-guard.service';
const routes: VexRoutes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'singleUseToken/:token/:rfqItem_priceCode/:hightlight',
    loadChildren: () => import('./pages/auth/single-use-token/single-use-token.module').then(m => m.singleUseTokenModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'signUp/:token',
    loadChildren: () => import('./pages/auth/signUp/signUp.module').then(m => m.signUpModule),
  },
  {
    path: 'VendorSignUp/:token',
    loadChildren: () => import('./pages/auth/vendor_signup/vendor_signup.module').then(m => m.VendorSignUpModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'apps/todos',
        data: {
          role: 'admin'
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/todos/todos.module').then(m => m.TodosModule)
      },
      {
        path: 'apps/todos/rfq',
        data: {
          role: 'admin'
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/todos/rfq/rfq.module').then(m => m.RfqModule)
      },
      {
        path: 'apps/todos/licensing',
        data: {
          role: 'admin'
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/todos/licensing/licensing.module').then(m => m.LicensingModule)
      },
      {
        path: 'apps/todos/production',
        data: {
          role: 'admin'
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/todos/production/production.module').then(m => m.ProductionModule)
      },
      {
        path: 'apps/itemLibrary',
        data: {
          role: 'admin'
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/itemLibrary/itemLibrary.module').then(m => m.ItemLibraryModule)

      }, {
        path: 'apps/prod-tracking',
        data: {
          role: 'admin'
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/prod_tracking/prod_tracking.module').then(m => m.ProdTrackingModule)

      }, {
        path: 'apps/licensing-tracking',
        data: {
          role: 'admin'
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/licensing_tracking/licensing_tracking.module').then(m => m.LicensingTrackingModule)

      },
      {
        path: 'apps/admin/editor',
        canActivate: [RoleGuard],
        data: {
          role: ['admin','production']
        },
        loadChildren: () => import('./pages/editor/editor.module').then(m => m.EditorModule)
      },
      {
        path: 'apps/admin/users',
        canActivate: [AuthGuard],
      
        loadChildren: () => import('./pages/manage_users/manage_users.module').then(m => m.ManageUsersModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'apps/itemDetail/:item', component: ItemDetailComponent,
        loadChildren: () => import('./pages/itemDetail/itemDetail.module').then(m => m.ItemDetailModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'apps/rfq',
        loadChildren: () => import('./pages/rfq/rfq.module').then(m => m.RFQModule)
      },      {
        canActivate: [AuthGuard],
        path: 'apps/standardized',
        loadChildren: () => import('./pages/standardized/standardized.module').then(m => m.StandardizedModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'apps/filterd_standardized/:std_prod_id',
        loadChildren: () => import('./pages/standardized/standardized.module').then(m => m.StandardizedModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'apps/filterd_rfq/:rfqItem_priceCode/:hightlight',
        loadChildren: () => import('./pages/rfq/rfq.module').then(m => m.RFQModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'apps/archive',
        data: {
          role: ['admin','designer', 'sales', 'production','sourcing_manager']
        },
        loadChildren: () => import('./pages/rfq/rfq.module').then(m => m.RFQModule)
      },
      {

        path: 'dashboards/analytics',
        redirectTo: '/'
      },
      {
        canActivate: [AuthGuard],
        path: 'apps/dashboards',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'apps',
        children: [
          {
            path: 'chat',
            loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'mail',
            loadChildren: () => import('./pages/apps/mail/mail.module').then(m => m.MailModule),
            data: {
              toolbarShadowEnabled: true,
              scrollDisabled: true
            }
          },
          {
            path: 'social',
            loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
          },
          {
            path: 'contacts',
            loadChildren: () => import('./pages/apps/contacts/contacts.module').then(m => m.ContactsModule)
          },
          {
            path: 'calendar',
            loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'aio-table',
            loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
          },
          {
            path: 'help-center',
            loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
          },
          {
            path: 'scrumboard',
            loadChildren: () => import('./pages/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
          },
          {
            path: 'editor',
            loadChildren: () => import('./pages/apps/editor/editor.module').then(m => m.EditorModule),
          },
        ]
      },
      {
        path: 'pages',
        children: [
          {
            path: 'pricing',
            loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
          },
          {
            path: 'faq',
            loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
          },
          {
            path: 'guides',
            loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
          },
          {
            path: 'invoice',
            loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
          },
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-401',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },
      {
        path: 'ui',
        children: [
          {
            path: 'components',
            loadChildren: () => import('./pages/ui/components/components.module').then(m => m.ComponentsModule),
          },
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'icons',
            loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
          },
          {
            path: 'page-layouts',
            loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
          },
        ]
      },
      {
        path: 'documentation',
        loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule),
      },
      {
        path: '**',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
