import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import decode from 'jwt-decode';
import { itemService } from 'src/app/helpers/services/main.service';
@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(public auth: AuthService, public router: Router, private itemService: itemService) { }
    async canActivate(route: ActivatedRouteSnapshot) {
        // this will be passed from the route config on the data property
        const roles: string | string[] = route.data.role; // Can be a string or an array of strings
    
        const token = localStorage.getItem('token');
        // decode the token to get its payload
        const tokenPayload = <any>decode(token);
        const userRole = tokenPayload.role;
      
        if (await this.auth.isAuthenticated()) {
            // Check if user role matches any of the allowed roles
            if (Array.isArray(roles) ? roles.includes(userRole) : roles === userRole) {
                return true;
            } else {
                this.router.navigate(['pages/error-401']);
                return false;
            }
        } else {
            this.router.navigate(['/login']);
        }
    }
    async isAuth(role: any) {
        const token = localStorage.getItem('token');
        // decode the token to get its payload
        const tokenPayload = <any>decode(token);
        if (await this.auth.isAuthenticated()) {
            if (tokenPayload.role != role) {
                return false;
            } else {
                return true;
            }
        } else {
            this.router.navigate(['/login']);
        }
    }
    getRole(){
        const token = localStorage.getItem('token');
        // decode the token to get its payload
        const tokenPayload = <any>decode(token);
        if ( this.auth.isAuthenticated()) {
           return tokenPayload.role
        }
    }
    getUserId(){
        const token = localStorage.getItem('token');
        // decode the token to get its payload
        const tokenPayload = <any>decode(token);
        return tokenPayload.user_id;
    }
}