import { Injectable } from '@angular/core'
import { Observable, pipe } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IVendor } from '../models/IVendor';
import { CommentInterface } from 'src/app/pages/comments/types/commentInterface';

@Injectable()
export class itemService {
  serverAddress = environment.url;
  constructor(private http: HttpClient) {
  }
  setUserAuth(): any {
    let token = localStorage.getItem("token")
    const headers = new HttpHeaders()
      .set('Authorization', token)
    return headers
  }
  getUserRole() {
    return this.http.get<any[]>(this.serverAddress + '/GetUserRole', { headers: this.setUserAuth() })
  }
  getItems() {
    return this.http.get<any[]>(this.serverAddress + '/getAllItems', { headers: this.setUserAuth() })
  }
  getItemImages(id) {
    return this.http.post(this.serverAddress + '/getItemImages', id, { headers: this.setUserAuth() })
  }
  getAllItem(data) {
    return this.http.post(this.serverAddress + '/getAllItem', data, { headers: this.setUserAuth() })
  }
  getVendros(data) {
    return this.http.post(this.serverAddress + '/getvendors', data, { headers: this.setUserAuth() })
  }
  onSave(data) {
    return this.http.post(this.serverAddress + '/save', data, { headers: this.setUserAuth() })
  }
  getGridHeader(data) {
    return this.http.post(this.serverAddress + '/viewlayout', data, { headers: this.setUserAuth() })
  }
  getUDFElements(data) {
    return this.http.post<any>(this.serverAddress + '/getUDFElement', data, { headers: this.setUserAuth() })
  }
  getUDFElementComponent() {
    return this.http.get(this.serverAddress + '/getUDFComponent', { headers: this.setUserAuth() })
  }
  getUDFElementType() {
    return this.http.get(this.serverAddress + '/getUDFElementType', { headers: this.setUserAuth() })
  }
  getUDFTabel() {
    return this.http.get(this.serverAddress + '/getUDFTabel', { headers: this.setUserAuth() })
  }
  getMG(data) {
    return this.http.post<any>(this.serverAddress + '/getMG', data, { headers: this.setUserAuth() })
  }
  getAllVendors() {
    return this.http.get(this.serverAddress + '/getAllVendors', { headers: this.setUserAuth() })
  }
  updateOrderFactory(orderedFactories: IVendor[]) {
    return this.http.patch<IVendor[]>(this.serverAddress + '/updateOrderFactory', { orderedFactories }, { headers: this.setUserAuth() })
  }
  getItemSize() {
    return this.http.get(this.serverAddress + '/findItemSize', { headers: this.setUserAuth() })
  }
  getItemDepth() {
    return this.http.get(this.serverAddress + '/findItemDepth', { headers: this.setUserAuth() })
  }
  getCustomers() {
    return this.http.get(this.serverAddress + '/findCustomers', { headers: this.setUserAuth() })
  }
  getLicenseList() {
    return this.http.get(this.serverAddress + '/findLicenseList', { headers: this.setUserAuth() })
  }
  getDeliveryLocation() {
    return this.http.get(this.serverAddress + '/findDeliveryLocation', { headers: this.setUserAuth() })
  }
  getRFQItemStatus() {
    return this.http.get(this.serverAddress + '/findRFQItemStatus', { headers: this.setUserAuth() })
  }
  uploadFile(file) {
    return this.http.post(this.serverAddress + '/uploadToGCS', file, { headers: this.setUserAuth() })
  }
  uploadThumb(file) {
    return this.http.post(this.serverAddress + '/getThumb', file, { headers: this.setUserAuth() })
  }
  getRFQAddProductLayout() {
    return this.http.get(this.serverAddress + '/getRFQAddProductLayout', { headers: this.setUserAuth() })
  }
  getCompanyCode() {
    return this.http.get(this.serverAddress + '/getCompanyCode', { headers: this.setUserAuth() })
  }
  makeNewUser(data) {
    return this.http.post(this.serverAddress + '/createUser', data, { headers: this.setUserAuth() })
  }
  getUserLoginInfo(data) {
    return this.http.post(this.serverAddress + '/findUserLoginInfo', data, { headers: this.setUserAuth() })
  }
  saveGridLayout(data) {
    console.log("Data to save", data)
    return this.http.post(this.serverAddress + '/saveGridLayout', data, { headers: this.setUserAuth() })
  }
  getCustomGridLayout(data) {
    return this.http.post(this.serverAddress + '/getCustomGridLayout', data, { headers: this.setUserAuth() })
  }
  updateRfqItem(data) {
    return this.http.post(this.serverAddress + '/updateRfqItem', data, { headers: this.setUserAuth() })
  }
  deleteRFQItemVendor(data) {
    return this.http.post(this.serverAddress + '/deleteRFQItemVendor', data, { headers: this.setUserAuth() })
  }
  sendPriceReq(data) {
    return this.http.post(this.serverAddress + '/sendPriceReq', data, { headers: this.setUserAuth() })
  }
  addingVendor(data) {
    return this.http.post(this.serverAddress + '/addingVendor', data, { headers: this.setUserAuth() })
  }
  updateVendor(data) {
    return this.http.post(this.serverAddress + '/updateVendor', data, { headers: this.setUserAuth() })
  }
  archivedRFQItem(data) {
    return this.http.post(this.serverAddress + '/archiveRFQItem', data, { headers: this.setUserAuth() })
  }
  archiveLibrary(data) {
    return this.http.post(this.serverAddress + '/archiveLibrary', data, { headers: this.setUserAuth() })
  }
  deleteRFQItem(data) {
    return this.http.post(this.serverAddress + '/deleteRFQItem', data, { headers: this.setUserAuth() })
  }
  getRFQGroup() {
    return this.http.get(this.serverAddress + '/getRFQGroup', { headers: this.setUserAuth() })
  }
  addNewRFQGroup(data) {
    return this.http.post(this.serverAddress + '/addNewRFQGroup', data, { headers: this.setUserAuth() })
  }
  getMerchGroup(data) {
    return this.http.post(this.serverAddress + '/getMerchGroup', data, { headers: this.setUserAuth() })
  }
  getRFQStep() {
    return this.http.get(this.serverAddress + '/getRFQStep', { headers: this.setUserAuth() })
  }
  addNewVendor(data) {
    return this.http.post(this.serverAddress + '/addNewVendor', data, { headers: this.setUserAuth() })
  }
  selectedVendor(data) {
    return this.http.post(this.serverAddress + '/selectedVendor', data, { headers: this.setUserAuth() })
  }
  generatePriceCode() {
    return this.http.get(this.serverAddress + '/generatePriceCode', { headers: this.setUserAuth() })
  }
  addRfqItem(data) {
    return this.http.post(this.serverAddress + '/addRfqItem', data, { headers: this.setUserAuth() })
  }
  getCustomGridLayoutList(data) {
    return this.http.post(this.serverAddress + '/getCustomGridLayoutList', data, { headers: this.setUserAuth() })
  }
  getGridAccessByRole(data) {
    return this.http.post(this.serverAddress + '/getGridAccessByRole', data, { headers: this.setUserAuth() })
  }
  getSelectedGridView(data) {
    return this.http.post(this.serverAddress + '/getSelectedGridView', data, { headers: this.setUserAuth() })
  }
  updateGridAccessLevel(data) {
    return this.http.post(this.serverAddress + '/saveGridAccessLevel', data, { headers: this.setUserAuth() })
  }
  getRFQDetailLayout() {
    return this.http.get(this.serverAddress + '/getRFQDetailLayout', { headers: this.setUserAuth() })
  }
  verifyRole(): any {
    return this.http.get(this.serverAddress + '/getUserRole', { headers: this.setUserAuth() })
  }
  verifySingleUseToken(data) {
    return this.http.post(this.serverAddress + '/verifySingleUseToken', data, { headers: this.setUserAuth() })
  }
  getCBM() {
    return this.http.get(this.serverAddress + '/getCBM', { headers: this.setUserAuth() })
  }
  getWhse() {
    return this.http.get(this.serverAddress + '/getWhse', { headers: this.setUserAuth() })
  }
  saveCBM(data) {
    return this.http.post(this.serverAddress + '/saveCBM', data, { headers: this.setUserAuth() })
  }
  saveWhse(data) {
    return this.http.post(this.serverAddress + '/saveWhse', data, { headers: this.setUserAuth() })
  }
  updateLicense(data) {
    return this.http.post(this.serverAddress + '/updateLicense', data, { headers: this.setUserAuth() })
  }
  sendSignUpInv(data) {
    return this.http.post(this.serverAddress + '/sendSignUpInv', data, { headers: this.setUserAuth() })
  }
  signup(data) {
    return this.http.post(this.serverAddress + '/signup', data)
  }
  verifyAuthToken(data) {
    return this.http.post(this.serverAddress + '/verifyAuthToken', data)
  }
  forgotPassw(data) {
    return this.http.post(this.serverAddress + '/forgotPassw', data)
  }
  changePassword(data) {
    return this.http.post(this.serverAddress + '/changePassword', data)
  }
  setRFQSteps(data) {
    return this.http.post(this.serverAddress + '/setRFQSteps', data, { headers: this.setUserAuth() })
  }
  getnotifications() {
    return this.http.get(this.serverAddress + '/getnotifications', { headers: this.setUserAuth() })
  }
  addMultipleVendor(data) {
    return this.http.post(this.serverAddress + '/addMultipleVendor', data, { headers: this.setUserAuth() })
  }
  findRFQItemByPriceCode(data) {
    return this.http.post(this.serverAddress + '/findRFQItemByPriceCode', data, { headers: this.setUserAuth() })
  }
  markNotificationAsRead(data) {
    return this.http.post(this.serverAddress + '/markNotificationAsRead', data, { headers: this.setUserAuth() })
  }
  addNewFactory(data) {
    return this.http.post(this.serverAddress + '/addNewFactory', data, { headers: this.setUserAuth() })
  }
  addAllassignedVendors(data) {
    return this.http.post(this.serverAddress + '/addAllassignedVendors', data, { headers: this.setUserAuth() })
  }
  sendMultiplePriceReq(data) {
    return this.http.post(this.serverAddress + '/sendMultiplePriceReq', data, { headers: this.setUserAuth() })
  }
  getAllUsers(data) {
    return this.http.post(this.serverAddress + '/getAllUsers', data, { headers: this.setUserAuth() })
  }
  updateUser(data) {
    return this.http.post(this.serverAddress + '/updateUser', data, { headers: this.setUserAuth() })
  }
  getAllTemporaryVendors() {
    return this.http.get(this.serverAddress + '/getAllTemporaryVendors', { headers: this.setUserAuth() })
  }
  getAuditLog(data) {
    return this.http.post(`${this.serverAddress}/getAuditLog`, data, { headers: this.setUserAuth() })
  }
  saveLog(data) {
    return this.http.post(`${this.serverAddress}/saveLog`, data, { headers: this.setUserAuth() })
  }
  newCBM(data) {
    return this.http.post(`${this.serverAddress}/newCBM`, data, { headers: this.setUserAuth() })
  }
  getPreSignedUrl(data: any) {
    return this.http.post(`${this.serverAddress}/getPreSignedUrl`, data)
  }
  addComment(comment: CommentInterface) {
    return this.http.post<any>(`${this.serverAddress}/api/comment/addComment`, comment, { headers: this.setUserAuth()})
  }
  getComments(id: number) {
    return this.http.get<any>(`${this.serverAddress}/api/comment/getComments/${id}`, { headers: this.setUserAuth() })
  }
  updateComment(id: number, comment: string) {
    return this.http.post<any>(`${this.serverAddress}/api/comment/updateComment/${id}`, {comment}, { headers: this.setUserAuth()})
  }
  deleteComment(id: number) {
    return this.http.delete<any>(`${this.serverAddress}/api/comment/deleteComment/${id}`, { headers: this.setUserAuth()})
  }
  getAuditLogByItemDetailId(id:number, moduleName:string) {
    return this.http.get(`${this.serverAddress}/getAuditLogByItemDetailId/${id}/${moduleName}`, { headers: this.setUserAuth() })
  }
  saveTechLink(fileName: object, itemId: number) {
    return this.http.post(`${this.serverAddress}/saveTechLink/${itemId}`, fileName)
  }
  deleteBucketObjects(params: object) {
    return this.http.post(`${this.serverAddress}/deleteBucketObjects`, params)
  }
  getAdditionalUserEmail(userId: number) {
    return this.http.get(`${this.serverAddress}/getAdditionalUserEmail/${userId}`, { headers: this.setUserAuth() })
  }
  addAdditionalUserEmail(data: any) {
    return this.http.post(`${this.serverAddress}/addAdditionalUserEmail`, data, { headers: this.setUserAuth() })
  }
  getSeasonCode() {
    return this.http.get(`${this.serverAddress}/getSeasonCode`, { headers: this.setUserAuth() })
  }
  getItemAttachements(id: number) {
        return this.http.get(`${this.serverAddress}/getItemAttachments/${id}`, { headers: this.setUserAuth() })
  }
  uploadThumbnailImage(formData: any){
        return fetch(`${this.serverAddress}/getThumbnailImage`, {
            method: 'POST', // Assuming you want to upload via POST method
            body: formData,
            headers: this.setUserAuth(),
            // Use type assertion to include 'responseType' property
            // @ts-ignore
            responseType: 'blob' // Add responseType option
        });
    }
    deleteImageByUUID(uuid: string) {
        return this.http.delete(`${this.serverAddress}/deleteImageByUUID/${uuid}`, { headers: this.setUserAuth() })
    }
  getUserRolePermissions(userId: number) {
      return this.http.get(`${this.serverAddress}/api/role/getUserRolePermissions/${userId}`, { headers: this.setUserAuth() })
  }
}

