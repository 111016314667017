import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { HelperService } from 'src/app/helpers/services/helper.service';
import { ItemDetailService } from 'src/app/helpers/services/item_detail.service';
import { itemService } from 'src/app/helpers/services/main.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})

export class ImageUploadComponent implements OnInit {
  public imageFiles: any[] = [];
  detailImages: any[] = []
  detailThumbs: any[] = [];
  GalleryItems: GalleryItem[] = []
  imageSliderObject: any[] = []
  isLoading = false
  itemAttachments
  primaryImage
  noImage = "https://storage.googleapis.com/designflow-default-bucket/No_Image.jpg";

  @Input() itemDetailId: number
  @Input() attachments: any = [];
  @Input() designRefNumber: string
  @Output() refreshItemData: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private itemService: itemService,
    public gallery: Gallery,
    public ItemDetailService: ItemDetailService,
    private _snackBar: MatSnackBar,
    private helperService:HelperService
  ) { }

  ngOnInit(): void {
    this.getImageAttachments()
  }

  getImageAttachments = async () => {
    this.GalleryItems = [];
    this.imageSliderObject = [];
    this.detailImages = [];
    this.detailThumbs = [];

    this.itemService.getItemAttachements(this.itemDetailId).toPromise().then(async(itemDetailsData: any) => {
      this.itemAttachments = itemDetailsData;

      itemDetailsData = itemDetailsData.filter(item => item.item_attachment_fileName.toLowerCase() !== 'no_image.jpg')

      // Separate images and thumbnails into detailImages and detailThumbs arrays
      itemDetailsData.forEach(element => {
        if(element.primary_image) this.primaryImage = element.uuid
       // if (element.uuid) {
          if (element.attachment_display_name === 'Image') {
            this.detailImages.push(element);
          } else if (element.attachment_display_name === 'Thumb') {
            this.detailThumbs.push(element);
          }
       // }
      });

      // Combine images and thumbnails using UUID for synchronization
      this.detailImages.forEach(image => {
        const matchingThumb = this.detailThumbs.find(thumb => thumb.uuid === image.uuid);
        if (matchingThumb) {
          this.GalleryItems.push(new ImageItem({ src: image.attachment_link }));
          this.imageSliderObject.push({
            thumbImage: matchingThumb.attachment_link,
            uuid: matchingThumb.uuid,
          });
        }
      });

      // By default set one primary image if available else show no image
      if(itemDetailsData.length > 0 && !this.primaryImage){
        const defaultItem = itemDetailsData.find((item => item.uuid !== null)) || itemDetailsData[0];
        if(defaultItem.uuid) this.changePrimary(defaultItem.uuid)
          else{
                // If the image does not have uuid, add one
                const uuid = defaultItem.uuid || uuidv4()
                let fileName = defaultItem.item_attachment_fileName;
                fileName = (defaultItem.attachment_display_name.toLowerCase() === 'image') ? fileName.replace('images-','') : fileName.replace('thumbs-','')
                await this.updateUuid(uuid, defaultItem.item_num_id_fk, fileName, true);
                this.getImageAttachments();
        }
      } else if(itemDetailsData.length === 0) {
        this.GalleryItems.push(new ImageItem({ src: this.noImage }));
        this.imageSliderObject.push({
          thumbImage: this.noImage
        });
      }

      // Make sure input is cleared to detect file upload change if you upload same files again
      (<HTMLInputElement>document.getElementById('image')).value = '';

          // Load the images into the gallery
      await this.gallery.ref().load([...this.GalleryItems]);
    })
  }


  onFileChange(pFileList: File[]) {
    console.log(pFileList)
    this.imageFiles = Object.keys([...pFileList]).map(key => pFileList[key]);
  }
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onFileDrop(event: DragEvent): void {
    event.preventDefault();

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.onFileChange(this.imageFiles);
    }
  }

  isEmpty = (str) => !str || (typeof str === 'string' && !str.trim().length);

  async onFileSave() {

    if (!this.imageFiles.length) {
      this._snackBar.open("You need to choose file first!", 'Close', {
        duration: 2000,
      });
      return;
    }
    const fileCount = this.imageFiles.length;
    this.isLoading = true

    //Iterate through each file in the files array
    for (let i = 0; i < fileCount; i++) {
      const file: File = this.imageFiles[i];
      const contentType = file.type;
      const fileName = `${new Date().getTime()}-${file.name.toLowerCase()}`;
      const imageName = `images-${fileName}`
      const thumbName = `thumbs-${fileName}`;
      const uuid = uuidv4()

      try{
        await this.helperService.saveToDigitalOceanAndDB(imageName, contentType, file);
        await this.saveImageLink(contentType,'Image',imageName,uuid);

        const thumbFile = await this.helperService.resizeImage(file, thumbName);
        await this.helperService.saveToDigitalOceanAndDB(thumbName, contentType, thumbFile);
        await this.saveImageLink(contentType, 'Thumb', thumbName, uuid)
        
        await this.getImageAttachments();

        // Design ID creation is on hold for now , will be handled via DFLOW-35
        // if(this.isEmpty(this.designRefNumber)){
        //   console.log(`this.designRefNumber is empty so create a new one `)
        //   this.designRefNumber = `DSN-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
        //   console.log(` this.itemDetailId`,  this.itemDetailId)
        //   console.log(` this.designRefNumber`,  this.designRefNumber)
        //   await this.ItemDetailService.updateItemHeader({
        //         id: this.itemDetailId, 
        //         dsn_ref_num: this.designRefNumber
        //   }).subscribe(data => {
        //     this.refreshItemData.emit()
        //     this._snackBar.open(`Design Reference Number is ${this.designRefNumber} `, 'Close', {
        //       duration: 2000,
        //     });
        //   })
        // }
        
        this.isLoading = false
      }
       catch(e){
        console.log(e);
        this.isLoading = false;
      }
    }
    this.imageFiles = [];
  }

  changePrimary(uuid){
    const item = this.itemAttachments.find(item => item.uuid === uuid);
    this.ItemDetailService.setPrimaryImage(item).subscribe((res:any) => {
      if(res.statusCode && res.statusCode === 200 ) {
        this.primaryImage = uuid;
        this._snackBar.open('Primary image changed!', 'ok', { duration: 3000 });
      }
      else this._snackBar.open('Error updating image', 'ok', { duration: 3000 });
    })

  }

  async saveImageLink(contentType: string, attachment_display_name: string, fileName: string, uuid: string): Promise<Object>{
    const body = {
      flag: 'image-thumbnail-create',
      item_num_id_fk: this.itemDetailId,
      attachment_type: contentType,
      attachment_display_name:attachment_display_name,
      fileName:fileName,
      uuid:uuid
    };

    // Save the file link to your database
    return this.itemService.saveTechLink(body, this.itemDetailId).first().toPromise()
  }

  async updateUuid(uuid: string, item_num_id_fk, fileName: string, primary_image: boolean): Promise<Object>{
    const body = {
      flag: 'item-attachment-update-uuid',
      item_num_id_fk, uuid, primary_image, fileName
    };

    // Save the new primary image with uuid
    return this.itemService.saveTechLink(body, item_num_id_fk).first().toPromise()
  }

  deleteImage(uuid, imageIndex) {
    const fileName = this.imageSliderObject.find(image => image.uuid === uuid).thumbImage;
    this.imageSliderObject = this.imageSliderObject.filter((image, index) => index !== imageIndex);

    const files = [fileName, fileName.replace('thumbs-','')]
    const params = {Bucket:'dflowbucket',files:files}
    this.itemService.deleteBucketObjects(params).subscribe((res) => {
      this.itemService.deleteImageByUUID(uuid).subscribe(async (data) => {
        if(this.primaryImage === uuid) this.primaryImage = "";
        setTimeout(async () => {
          await this.getImageAttachments();
          this.gallery.resetAll()
          this.isLoading = false;
          this._snackBar.open('Image Deleted Successfully', 'ok', { duration: 3000 });
        }, 0);
      });
    }, (err) => this._snackBar.open('Unable to delete image', 'ok', { duration: 3000 }))
  }
}
