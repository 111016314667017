<div style="width: 100%; overflow-x: hidden;">
    <div mat-dialog-actions align="end" class="custom-dialog-actions">
        <button mat-icon-button mat-dialog-close cdkFocusInitial class="small-close-button">
            <mat-icon aria-hidden="false" aria-label="Close icon" style="font-size: 16px;">close</mat-icon>
        </button>
    </div>
    <table mat-table [dataSource]="attachments">
        <!-- Position Column -->
        <!-- <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container> -->

        <!-- <ng-container matColumnDef="attachment_type">
            <th mat-header-cell *matHeaderCellDef> Attachment Type </th>
            <td mat-cell *matCellDef="let element"> {{element.attachment_type}} </td>
        </ng-container> -->

        <ng-container matColumnDef="item_attachment_fileName">
            <th mat-header-cell *matHeaderCellDef> Attachment Link </th>
            <td mat-cell *matCellDef="let element"> 
                <a [href]="element.attachment_link" target="_blank"
                   download="{{element.item_attachment_fileName}}">
                    {{element.attachment_link}}
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" 
                   showFirstLastButtons 
                   aria-label="Select page of periodic elements">
    </mat-paginator>
</div>